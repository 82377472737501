import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { DfpsButton, DfpsConfirm, DfpsDirtyCheck, DfpsFormValidation, DfpsInput } from 'dfps-web-lib';
import { LogonComponent } from './logon/logon.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TareHeaderComponent } from './tare-header/tare-header.component';
import { RegisterComponent } from './register/register.component';
import { AccessErrorComponent } from './access-error/access-error.component';
import { NewTareResetPasswordComponent } from './logon/new-tare-reset-password/new-tare-reset-password.component';


@NgModule({
  declarations: [
    LogonComponent,
    TareHeaderComponent,
    RegisterComponent,
    AccessErrorComponent,
    NewTareResetPasswordComponent
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    ReactiveFormsModule,
    DfpsButton, DfpsConfirm, DfpsDirtyCheck, DfpsFormValidation, DfpsInput,
    NgbModule
  ],
  exports: [
    TareHeaderComponent
  ]
})
export class AuthenticationModule { }
