<app-ooc-header-side-nav *ngIf="showOOCHeader"></app-ooc-header-side-nav>

<div style="margin: 25px;">
  <div class="Content">
    <section style="min-width: auto;">
      <!-- <dfps-header></dfps-header> -->

      <app-tare-header *ngIf="isUserLoggedIn"></app-tare-header>
      <app-primary-nav *ngIf="isUserLoggedIn && loadApplication && showPrimaryNavTab"
        [isUserLoggedIn]="isUserLoggedIn"></app-primary-nav>

      <div class="row">
        <div class="col-sm-12 col-lg-2 pl-0" *ngIf="isUserLoggedIn && loadApplication && showSideNavTab">
          <app-secondary-side-nav [showSideNavFor]="showSideNavFor"> </app-secondary-side-nav>
        </div>

        <div class="col-sm-12" [ngClass]="showSideNavTab ?'col-md-10 pl-0':''">

          <app-teritiary-nav *ngIf="isUserLoggedIn" [hidden]="!showTeritiaryTab"
            [showTeritiaryNavFor]="showTeritiaryNavFor">
          </app-teritiary-nav>

          <main #mainContent style="min-height: calc(100vh - 250px);">
            <div class="container-fluid bodycolor">
              <dfps-global-message></dfps-global-message>
              <dfps-progress-bar></dfps-progress-bar>
              <div id="pageContent" class="pt-2" *ngIf="loadApplication">
                <!-- <ng-template #logon> -->
                <router-outlet></router-outlet>
                <!-- </ng-template> -->
                <div class="mt-4" *ngIf="isUserLoggedIn">
                  <!-- <p>If you are having problems viewing this site, we recommend upgrading to the most
              recent version of your browser. If you are still having problems,
              <a href="javascript:obfuscator('tare','dfps.texas.gov');">contact us</a>.
            </p> -->
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
  </div>

  <app-ooc-footer *ngIf="showOOCHeader"></app-ooc-footer>
</div>