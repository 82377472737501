<div class="modal-content">
    <div class="modal-header">
        <h3 id="modalHeader" class="h5 modal-title  text-white">{{fileUploadinfo?.modalHeading}}</h3>
        <button type="button" class="close text-white" aria-label="Close" (click)="closeModal()">
            &times;
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <h4 class="h5 modal-title">{{fileUploadinfo?.sectionHeading}}</h4>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group ">
                <p-fileUpload name="file" #createUploader customUpload="true" [multiple]="fileUploadinfo?.multiple"
                    (onSelect)="onSelect($event, createUploader)"
                    (uploadHandler)="uploadHandler($event, createUploader)" [accept]="fileUploadinfo?.accept"
                    maxFileSize="15000000" [disabled]="fileUploadinfo?.disabled">

                    <ng-template let-file let-i="index" pTemplate="file" [ngIf]="createUploader?.files?.length">
                        <table>
                            <tbody>
                                <tr>
                                    <td colspan="4" class="boldBody"><strong>Files Ready To Upload</strong></td>
                                </tr>
                                <tr>
                                    <th>Name</th>
                                    <th class="pl-4">Size</th>
                                    <th class="pl-4">Date</th>
                                </tr>
                                <tr>
                                    <td>{{file.name}}</td>
                                    <td class="pl-4">{{file.size | fileSizeConverter: 'KB'}}</td>
                                    <td class="pl-4">{{currentDate | date:'MM/dd/yyyy'}}</td>
                                    <td class="pl-4"><button class="marginLeft8 blueBackground" icon="pi pi-times"
                                            pButton aria-label="Remove File" type="button"
                                            (click)="onRemove($event,file, createUploader)" title="Remove File">
                                            <span class="sr-only">Remove</span></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>

                    <ng-template pTemplate="content">
                        <table *ngIf="uploadedFilesList?.length>0">
                            <tbody>
                                <tr>
                                    <td colspan="3" class="boldBody"><strong>Uploaded Files</strong></td>
                                </tr>
                                <tr>
                                    <th>Name</th>
                                    <th class="pl-4">Size</th>
                                    <th class="pl-4">Date</th>
                                </tr>
                                <tr *ngFor="let list of uploadedFilesList">
                                    <td><a tabindex="0" class="anchorTagColor" (keypress)="downloadFile(list.fileId)"
                                            (click)="downloadFile(list.fileId)">{{list.fileName}}</a>
                                    </td>
                                    <td class="pl-4">{{list.size | fileSizeConverter: 'KB'}}</td>
                                    <td class="pl-4">{{list.uploadedDate | date:'MM/dd/yyyy'}}</td>
                                    <td class="pl-4">
                                        <button class="marginLeft8 blueBackground" icon="pi pi-times" pButton
                                            aria-label="Delete File" type="button"
                                            (click)="deleteFile(list.fileId, createUploader)" title="Delete File"
                                            *ngIf="(fileUploadinfo?.pageAccess?.pageWriteAccess ||  fileUploadinfo?.pageAccess?.uploadAccess) && list.deleteIndicator">
                                            <span class="sr-only">Delete</span></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </p-fileUpload>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>
    </div>
</div>