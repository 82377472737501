import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-new-tare-reset-password',
  templateUrl: './new-tare-reset-password.component.html',
  styleUrls: ['./new-tare-reset-password.component.css']
})
export class NewTareResetPasswordComponent {

  oOCAdoptionFosterCareUrl = environment.oOCAdoptionFosterCareUrl;

  constructor() { }

}
