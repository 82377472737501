<!doctype html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <title>Change your password</title>
  <meta name="description"
    content="This is the Texas Department of Family and Protective Services web site.Welcome to the TARE website! The Texas Adoption Resource Exchange (TARE) website helps match children awaiting adoption with adoptive parents.">
  <meta name="viewport" content="width=device-width, initial-scale=1">
</head>

<body>
  <div class="row">
    <div class="d-flex col-12 justify-content-center contentDiv"
      title="This is an image of the DFPS Agency Banner. This is a popup message requesting that you change your password to use the new login page.">
      <img src="{{oOCAdoptionFosterCareUrl}}/images/common/DFPS_logo.png"
        alt="This is an image of the DFPS Agency Banner">
    </div>
  </div>

  <div class="row">
    <div class="d-flex col-12 justify-content-center">
      <h2 class="justify-content-center text-danger">Welcome to the new secure Texas Adoption Resource Exchange (TARE)
        website.</h2>
    </div>
  </div>

  <div class="row">
    <div class="d-flex col-12 justify-content-center">
      <p>If you have already reset your password, close this window to continue with your
        sign in.</p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 justify-content-center">
      <p>To all others, to use the new system you will need to reset your password.<br></p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 justify-content-center">
      <p class="mb-0">Close this window, and click the</p>
      <p>"Forgot your password" link.</p><br>
    </div>
  </div>

  <div class="row">
    <div class="d-flex col-12 justify-content-center">
      <p><button type="button" class="closeButton" onclick="window.close()">Close Window</button></p>
    </div>
  </div>

</body>

</html>