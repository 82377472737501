import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})
export class ImageViewerComponent implements OnInit {

  @Input() imageVierInfo: any;

  spansishLangSelected: any;

  constructor(
    private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.dismiss();
  }

}
