import { AfterViewInit, Component, ContentChild, HostListener, Inject, OnInit, QueryList, ViewEncapsulation } from '@angular/core';
import { AppInsightMonitoringService } from './modules/shared/services/AppInsightMonitoringService';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { userData } from '@shared/services/user-data';
import { SetTitleService } from '@shared/services/set-title.service';
import { AppUtilService } from '@shared/services/app-util.service';
import { DataShareService } from '@shared/services/data-share.service';
import { DfpsSessionTimeoutComponent } from 'dfps-web-lib';
import { environment } from 'environments/environment';
import { Idle } from '@ng-idle/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@ngneat/transloco';
import { AppLanguages } from '@shared/enums/app-languages';

@Component({
  selector: 'dfps-app-root',
  templateUrl: './app.component.html',
  styleUrls: ["../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../.././node_modules/ngx-bootstrap/datepicker/bs-datepicker.css",
    "../.././node_modules/font-awesome/css/font-awesome.css",
    "../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../.././node_modules/primeng/resources/primeng.min.css",
    "../.././node_modules/primeicons/primeicons.css",
    '../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    '../.././node_modules/dfps-web-lib/resources/styles/css/bootstrap-glyphicons.css',
    './app.component.css',
  ],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit, AfterViewInit {
  @ContentChild(DfpsSessionTimeoutComponent) dfpsSessionTimeoutPopupComponent: QueryList<any>;
  isUserLoggedIn: boolean = false;
  showSideNavTab: boolean;
  showSideNavFor: string;
  showTeritiaryTab: boolean;
  showTeritiaryNavFor: string;
  showPrimaryNavTab = true;
  isAdmin: boolean = false;
  isCaseWorker: boolean = false;
  userInfo: AccountInfo;
  private readonly _destroy = new Subject<void>();
  loadApplication: boolean;
  showOOCHeader = true;
  onLoadUrl: string;
  timer = environment.idleSessionDuration;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  interval;
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    clearInterval(this.interval);
    this.timerInterval(this.timer);
    this.reset();
  }

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadCastService: MsalBroadcastService,
    private setTitleService: SetTitleService,
    private router: Router,
    private monitoringService: AppInsightMonitoringService,
    private authService: MsalService,
    private appUtilService: AppUtilService,
    private shareService: DataShareService,
    private idle: Idle,
    private modalService: BsModalService,
    private translocoService: TranslocoService
  ) { };

  ngOnInit() {
    console.log('Version: ' + '2024.10.11.1');
    this.setTitleService.setTitle();

    this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;

    this.msalBroadCastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.monitoringService.logPageView("PageUrl", this.router.url);
        const roles = (payload.idTokenClaims as userData)?.extension_Roles;

        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadCastService.inProgress$.pipe
      (filter((interactionStatus: InteractionStatus) =>
        interactionStatus == InteractionStatus.None),
        takeUntil(this._destroy))
      .subscribe(x => {
        this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
        if (this.isUserLoggedIn) {
          this.userInfo = (this.authService.instance.getAllAccounts()[0] as AccountInfo);
          this.getFamilyProfileInfo();
        } else {
          this.loadApplication = true;
        }
      })

    /* if (this.appUtilService.isGoogleTranslateSpanish$$?.value) {
      this.translocoService.setActiveLang('es');
    } */

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf('/familyProfile') > -1) {
          this.showSideNavFor = 'familyProfile';
          this.showSideNavTab = true;
          if (this.router.url.indexOf('/familyProfile/familyChildCharacteristicPreferences') > -1) {
            this.showTeritiaryTab = true;
            this.showTeritiaryNavFor = 'childCharPref';
          } else {
            this.showTeritiaryTab = false;
            this.showTeritiaryNavFor = '';
          }
        } else if (this.router.url.indexOf('/childProfile') > -1 || this.router.url.indexOf('/cpaSpecialNeeds') > -1) {
          this.showSideNavFor = 'childProfile'
          this.showSideNavTab = true;
          if (this.router.url.indexOf('/cpaSpecialNeeds') > -1) {
            this.showTeritiaryTab = true;
            this.showTeritiaryNavFor = 'cpaCharPref';
          } else {
            this.showTeritiaryTab = false;
            this.showTeritiaryNavFor = '';
          }
        }
        else if (this.router.url.indexOf('/information-meetings') > -1) {
          this.showSideNavTab = false;
          this.showSideNavFor = '';
          this.showTeritiaryTab = false;
          this.showTeritiaryNavFor = '';
          this.showPrimaryNavTab = false;
        }
        else if (this.router.url.indexOf('/newTareResetPassword') > -1) {
          this.showSideNavTab = false;
          this.showSideNavFor = '';
          this.showTeritiaryTab = false;
          this.showTeritiaryNavFor = '';
          this.showPrimaryNavTab = false;
          this.showOOCHeader = false;
        } else {
          this.showSideNavTab = false;
          this.showSideNavFor = '';
          this.showTeritiaryTab = false;
          this.showTeritiaryNavFor = '';
        }
        if (!(this.router.url.indexOf('/familyProfile') > -1)) {
          this.shareService.removeDataFromMap('familyProfileSuccessMsg');
        }
        if (!(this.router.url.indexOf('/logon') > -1) && !(this.router.url.indexOf('/createAccount') > -1)
          && !(this.router.url.indexOf('/application/TAREPublic/logon') > -1) && !(this.router.url.indexOf('/unauthorized') > -1)
          && !(this.router.url.indexOf('/externalRedirect') > -1)) {
          if (this.isUserLoggedIn && !this.getFamilyProfileInfo()) {
            this.router.navigate(['/logon']);
          }
        }
      }
    });

    this.monitoringService.logPageView('poc', this.router.url);
    this.logoutOnUseInactive();
  }

  async getFamilyProfileInfo() {
    await this.appUtilService.getFamilyProfileInfo();
    this.loadApplication = this.appUtilService.familyProfileInfoLoaded;
    return this.loadApplication;
  }

  logoutOnUseInactive() {
    this.timerInterval(this.timer);

    // sets an idle timeout of configured seconds.
    this.idle.setIdle(this.timer - 300);

    // sets a timeout period of 300 seconds(5 minutes). 300 seconds(5 minutes) of inactivity, the user will be considered timed out.
    this.idle.setTimeout(300);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    // this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.authService.logoutRedirect({ postLogoutRedirectUri: environment.redirectUri });
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      if (this.isUserLoggedIn) {
        this.modalService.show(DfpsSessionTimeoutComponent, { class: 'modal-sm modal-dialog-centered' });
      }
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });
    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  two(x) { return ((x > 9) ? '' : '0') + x; }

  time(ms) {
    let t = '';
    let timeToShow = localStorage.getItem('timeToShow');
    if (timeToShow !== undefined && timeToShow === '') {
      timeToShow = '';
    }
    let sec = Math.floor(ms / 1000);
    let min = Math.floor(sec / 60);
    sec = sec % 60;
    t = this.two(sec);

    min = min % 60;
    t = this.two(min) + ':' + t;
    localStorage.setItem('timeToShow', t);
    return 'Your Session Will Time Out In ' + t;
  }

  timerInterval(defaultTime) {
    let time = defaultTime * 1000;
    this.interval = setInterval(() => {
      time -= 1000;
      window.status = this.time(time);
      if (time === 0) {
        window.status = 'Your session has been disconnected';
        clearInterval(this.interval);
      }
    }, 1000);

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.onLoadUrl = this.router.url;
    }, 0);

    const observer = new MutationObserver(() => {
      const allAnchorTags = document.querySelectorAll('a');
      const espanolHref = Array.from(allAnchorTags).find(aTag => aTag.href.includes('/espanol.asp'));

      if (espanolHref && document.documentElement.className.match('translated') && (document.getElementsByTagName('html')[0].lang === AppLanguages.Spanish)) {
        this.appUtilService.isGoogleTranslateSpanish$$.next(true);
      } else {
        this.appUtilService.isGoogleTranslateSpanish$$.next(false);
      }
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false
    });
  }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

}
