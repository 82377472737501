import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { ComponentsComponent } from './components/components.component';
import { PrimaryNavComponent } from './components/primary-nav/primary-nav.component';
import { MediaComponent } from './components/media/media.component';
import { DfpsProgressBar } from 'dfps-web-lib';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { AccessibilityPTableTareDirective } from './directives/accessibility-p-table-tare.directive';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { FileSizeConverterPipe } from './pipes/fileSizeConverter.pipe';
import { SecondarySideNavComponent } from './components/secondary-side-nav/secondary-side-nav.component';
import { TeritiaryNavComponent } from './components/teritiary-nav/teritiary-nav.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { AccessibilityPOrderListDirective } from './directives/accessibility-porder-list.directive';
import { AccessibilityPDataViewTareDirective } from './directives/accessibility-p-data-view-tare.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';



@NgModule({
  declarations: [
    PrimaryNavComponent,
    SecondarySideNavComponent,
    TeritiaryNavComponent,
    ComponentsComponent,
    MediaComponent,
    FileUploadComponent,
    AccessibilityPDataViewTareDirective,
    AccessibilityPTableTareDirective,
    AccessibilityPOrderListDirective,
    PageNotFoundComponent,
    FileSizeConverterPipe,
    SecondarySideNavComponent,
    TeritiaryNavComponent,
    PhoneMaskDirective,
    ImageViewerComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    DfpsProgressBar,
    FileUploadModule,
    ReactiveFormsModule,
    TranslocoRootModule
  ],
  exports: [
    PrimaryNavComponent,
    SecondarySideNavComponent,
    TeritiaryNavComponent,
    MediaComponent,
    FileUploadComponent,
    AccessibilityPDataViewTareDirective,
    AccessibilityPTableTareDirective,
    AccessibilityPOrderListDirective,
    PhoneMaskDirective
  ]
})
export class SharedModule { }
