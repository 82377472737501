<div class="modal-content">
    <div class="modal-header">
        <h3 id="modalHeader" class="h5 modal-title text-white">{{imageVierInfo?.modalHeading}}</h3>
        <button type="button" class="close text-white" aria-label="Close" (click)="closeModal()">
            &times;
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
                <h4 class="h5 modal-title">{{imageVierInfo?.sectionHeading}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="d-flex col justify-content-center">
                <img [src]="imageVierInfo?.mediaUrl" class="img-fluid" [alt]="imageVierInfo?.altText">
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <div class="col-sm-12 pt-3 align-self-end">
            <button type="button" class="btn btn-secondary pull-right" data-dismiss="modal"
                (click)="closeModal()">Close</button>
        </div>
    </div>
</div>